<template>
  <div class="sign-page">
    <div class="head">
      <p class="content">记账树的成长需要成长天数，每日进入签到页+1天(会员用户+2天)，如需要加速记账树成长，还需要完成额外的任务。</p>
      <p class="notice">(注：成长天数≠现实天数)</p>
    </div>
    <div class="image-content">
      <img
    src="//download-cos.yofish.com/yofish-gongjushiyebu/20230317182426136-sign-bg.png" alt="" />
    </div>
    <div class="intro">
      <p class="title">种子茁壮成长之路</p>
      <p class="content">1.若您种的记账树累计成长天数600天,记账树即可成长为皇冠树。</p>
      <p class="content">2.在第一颗树已经成为皇冠树的基础上，保留原皇冠树，并且获得第二颗记账树种子（可无限叠加），第二颗种子的成长之路规则不变。</p>
      <p class="content">3.活动最终解释权归有鱼记账所有，如有疑问请联系平台工作人员处理。
        <span class="notice">(工作日:9:00-18:00)</span></p>
    </div>
    <div class="intro margin">
      <p class="title">种树达人榜更新说明</p>
      <p class="content">每周一0点定时更新榜单，未更新完成时仍展示上一次的榜单。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SignIn',
  mounted() {
    document.title = '帮助';
  },
};
</script>
<style lang="scss" scoped>
  .sign-page {
    padding-top: 20px;
    padding-bottom: 100px;
    margin: 0 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    img {
      width: 100%;
    }
    .content {
      font-size: 24px;
      font-weight: 400;
      color: #333333;
      line-height: 34px;
    }
    .head > .notice {
      margin-top: 8px;
    }
    .notice {
      font-size: 24px;
      font-weight: 400;
      color: rgba(51,51,51,0.6);
      line-height: 34px
    }
    .title {
      font-size: 28px;
      font-weight: 500;
      color: #333333;
      line-height: 40px;
    }
    .image-content {
      margin-top: 38px;
      margin-bottom: 38px;
    }
    .intro .content {
      margin-top: 20px;
    }
    .margin {
      margin-top: 20px;
    }
  }
</style>
